import React from 'react'
import { Link } from 'gatsby'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Style
import * as S from './style'
import { ButtonLink } from 'src/components/ButtonLink'
import { orange, white } from 'src/styles/colors'
import { WIDTH_MD } from 'src/styles/breakpoints'

const Peac = () => {
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.AcesseOsEditaisSection>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='fs-18 lh-22 fs-md-24 lh-md-28 text-grayscale--500 fw-500 f-inter mb-3 mb-md-4'>PEAC - Maquininhas</h3>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 f-inter mb-0'>O presente leilão visa à cessão da carteira de créditos do programa PEAC - Maquininhas do BANCO INTER S.A., através da plataforma especializada Debitumx.</p>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 f-inter mb-4'>Em conformidade com o previsto nos inciso XIII da resolução CMN Nº 4.971, de 16 de dezembro DE 2021, informamos que o primeiro processo competitivo da carteira Peac - Maquininha foi concluído sem o recebimento de ofertas.
              <br />Assim, considerando o contido no item 6.1 do EDITAL DO PROCESSO COMPETITIVO DO BANCO INTER S/A Nº 002/2024, será realizada nova oferta dos referidos créditos.Os novos prazos de análise da carteira e envio de propostas poderão ser consultados na aba cronograma da Plataforma da Debitumx.
            </p>
            <div className='d-flex flex-column flex-md-row gap-between'>
              <ButtonLink
                as={Link}
                to='https://www.debitumx.com/'
                className='mb-3'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    element_action: 'click button',
                    element_name: 'Acessar leilão',
                    section_name: 'Acesse os editais',
                    redirect_url: 'https://www.debitumx.com/',
                  })
                }}
              >
                Acessar leilão
                {windowWidth >= WIDTH_MD ? <IconsSwitch icon='ic_chevron_right' lib='interco' customPath='action-navigation/' size='LG' color={white} /> : null}
              </ButtonLink>
              <ButtonLink
                href='https://marketing.bancointer.com.br/arquivos/pdf/credito/Leilao_Peac_Maquininhas_2024.pdf'
                target='_blank'
                rel='noreferrer'
                outline
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    element_action: 'click button',
                    element_name: 'Acessar edital',
                    section_name: 'Acesse os editais',
                    redirect_url: 'https://marketing.bancointer.com.br/arquivos/pdf/credito/Leilao_Peac_Maquininhas_2024.pdf',
                  })
                }}
              >
                Acessar edital
                {windowWidth >= WIDTH_MD ? <IconsSwitch icon='ic_chevron_right' lib='interco' customPath='action-navigation/' size='LG' color={orange[500]} /> : null}
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
    </S.AcesseOsEditaisSection>
  )
}

export default Peac
